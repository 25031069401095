
<script setup>
    import { ref, watch, computed } from "vue";
    import { hapticsImpact } from "mobile.utils.ts";

    import MBottomSheet from "mobile.vue.components.BottomSheet.vue";

    const props = defineProps({
        filter: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits([]);
    

    /* handlers */

    function selectOrder(index) {
        props.filter.selectOrder(index);
        props.filter.update();
        hapticsImpact("MediumImpact");
        setTimeout(() => {
            props.filter.showOrderSheet = false;
        }, 100);
    }
</script>

<template>
    <m-bottom-sheet v-model="filter.showOrderSheet" v-bind="$attrs" min-height="calc(80% - 4rem)" max-height="calc(80% - 4rem)">
        <template #title>{{ $t("Sort order") }}</template>
        <template #body>
            <div class="px-3">
                <template v-for="(order, index) in filter.orders">
                    <a class="d-flex align-items-center gap-2 list-item" :class="{ 'border-top': index > 0 }" style="color: black; font-size: 0.9em; padding: 0 1rem; text-decoration: none;" @click="selectOrder(index)">
                        <div class="flex-shrink-0">
                            <i class="far fa-circle text-muted" style="font-size: 1.5em;" v-if="filter.storage.order !== index" />
                            <i class="fas fa-dot-circle text-primary" style="font-size: 1.5em;" v-if="filter.storage.order === index" />
                        </div>
                        <div class="flex-shrink-0" style="padding: 0.75rem 0;">
                            {{ order.name }}
                        </div>
                    </a>
                </template>
            </div>
        </template>
    </m-bottom-sheet>
</template>

<style scoped>
    .list-item {
        background-color: white;

        transition: all 200ms ease-in-out;

        &:active {
            transition: all 0ms;
            background-color: rgb(95%, 95%, 95%);
        }
    }
</style>
